import React from "react"
import InjuryCare from "./icons/injuryCare"
import EmploymentPhysicals from "./icons/employmentPhysicals"
import ScreeningServices from "./icons/screeningServices"
import Vaccinations from "./icons/vaccinations"
import PhysicalTherapy from "./icons/physicalTherapy"
import * as styles from "./styles.module.less"

const ServicesBanner = () => {
  return (
    <div className={styles.servicesCard}>
      <div className={styles.card}>
        <div className={styles.servicesFigure}>
          <InjuryCare />
          <p>Injury Care</p>
        </div>
        <div className={styles.servicesFigure}>
          <EmploymentPhysicals />
          <p>Employment Physicals</p>
        </div>
        <div className={styles.servicesFigure}>
          <ScreeningServices />
          <p>Screening Services</p>
        </div>
        <div className={styles.servicesFigure}>
          <Vaccinations />
          <p>Vaccinations</p>
        </div>
        <div className={styles.servicesFigure}>
          <PhysicalTherapy />
          <p>Physical Therapy</p>
        </div>
      </div>
    </div>
  )
}

export default ServicesBanner
