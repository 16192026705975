import React from "react"

const Vaccinations = () => (
  <svg
    viewBox="0 0 133 133"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="66.6115" cy="66.0295" r="66.0295" fill="#F0F7FA" />
    <path
      d="M105.711 55.6601L99.5852 49.7299L103.756 45.7873L89.4517 32.2651L85.4113 36.0774L79.0901 29.9517L71.8239 36.9571L76.6137 41.584L40.3156 76.5788L43.8998 80.0327L36.9269 86.7775L40.4133 90.1336L28.0967 102.027C27.3799 102.711 27.3473 103.786 28.0316 104.438C28.6832 105.09 29.8237 105.057 30.5405 104.373L42.8571 92.4796L46.6368 96.129L53.6097 89.3842L57.2916 92.9358L93.5572 57.941L98.4448 62.6656L105.711 55.6601ZM89.4517 36.11L99.6829 45.7547L97.6302 47.6772L90.9179 41.3559L87.4315 37.9998L89.4517 36.11ZM46.6042 91.437L41.7493 86.7775L46.311 82.3787L51.1659 87.0382L46.6042 91.437ZM45.1705 76.5788L49.8952 72.0171L52.6322 74.6564C53.2839 75.3081 54.3917 75.3081 55.0434 74.6564C55.695 74.0047 55.695 72.962 55.0434 72.3104L52.3064 69.6711L56.5422 65.5981L68.6633 77.2631L57.2916 88.2112L45.1705 76.5788ZM71.0745 74.9496L58.9534 63.2847L63.5803 58.8208L66.3825 61.5252C67.0341 62.1769 68.142 62.1769 68.7937 61.5252C69.4453 60.8735 69.4453 59.8308 68.7937 59.1792L65.9915 56.4747L71.1397 51.4894L73.7138 53.9658C74.3655 54.6175 75.4733 54.6175 76.125 53.9658C76.7767 53.3141 76.7767 52.2714 76.125 51.6198L73.5509 49.1434L78.9923 43.8975L91.1134 55.5624L71.0745 74.9496ZM79.0901 39.2054L79.0249 39.238L76.6463 36.9571L79.0575 34.6111L100.856 55.6276L98.4448 57.9736L95.9684 55.595L96.0336 55.5298L79.0901 39.2054Z"
      fill="#003B71"
    />
    <path
      d="M45.1709 76.5783L49.8955 72.0166L52.6325 74.6559C53.2842 75.3076 54.3921 75.3076 55.0437 74.6559C55.6954 74.0042 55.6954 72.9616 55.0437 72.3099L52.3067 69.6706L56.5426 65.5977L68.6637 77.2626L57.292 88.2107L45.1709 76.5783Z"
      fill="#92CCF0"
    />
  </svg>
)

export default Vaccinations
