import React from "react"

const ScreeningServices = () => (
  <svg
    viewBox="0 0 133 133"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="66.8088" cy="66.0295" r="66.0295" fill="#F0F7FA" />
    <path
      d="M50.291 87.6061V60.1128H63.8157V87.6061C63.8157 91.3288 60.776 94.3684 57.0533 94.3684C53.3306 94.3343 50.291 91.3288 50.291 87.6061Z"
      fill="#92CCF0"
    />
    <path d="M91.3477 42.8604H80.1113V31.624L91.3477 42.8604Z" fill="#92CCF0" />
    <path
      d="M88.3905 65.2709H68.2961C67.3827 65.2709 66.665 64.5532 66.665 63.6398C66.665 62.7264 67.3827 62.0088 68.2961 62.0088H88.3905C89.3039 62.0088 90.0215 62.7264 90.0215 63.6398C90.0215 64.5532 89.3039 65.2709 88.3905 65.2709Z"
      fill="#003B71"
    />
    <path
      d="M88.3905 75.057H68.2961C67.3827 75.057 66.665 74.3393 66.665 73.426C66.665 72.5126 67.3827 71.7949 68.2961 71.7949H88.3905C89.3039 71.7949 90.0215 72.5126 90.0215 73.426C90.0215 74.3393 89.3039 75.057 88.3905 75.057Z"
      fill="#003B71"
    />
    <path
      d="M88.3905 84.8429H68.2961C67.3827 84.8429 66.665 84.1252 66.665 83.2119C66.665 82.2985 67.3827 81.5808 68.2961 81.5808H88.3905C89.3039 81.5808 90.0215 82.2985 90.0215 83.2119C90.0215 84.1252 89.3039 84.8429 88.3905 84.8429Z"
      fill="#003B71"
    />
    <path
      d="M96.513 43.8712C96.513 43.806 96.4804 43.7407 96.4804 43.6755C96.4151 43.3819 96.2846 43.1209 96.0889 42.9252L79.7785 26.6148C79.5828 26.4191 79.3218 26.2886 79.0282 26.2234C78.963 26.1907 78.8977 26.1907 78.8325 26.1907C78.7346 26.1581 78.6694 26.1255 78.6041 26.1255H44.3523C40.764 26.1255 37.8281 29.0614 37.8281 32.6497V97.8913C37.8281 101.48 40.764 104.415 44.3523 104.415H90.0214C93.6097 104.415 96.5456 101.48 96.5456 97.8913V44.0669C96.5456 44.0017 96.513 43.9365 96.513 43.8712ZM90.9674 42.4359H80.2352V31.7036L90.9674 42.4359ZM93.2835 97.8913C93.2835 99.6854 91.8156 101.153 90.0214 101.153H44.3523C42.5581 101.153 41.0902 99.6854 41.0902 97.8913V32.6497C41.0902 30.8555 42.5581 29.3876 44.3523 29.3876H76.9731V44.0669C76.9731 44.9803 77.6908 45.698 78.6041 45.698H93.2835V97.8913Z"
      fill="#003B71"
    />
    <path
      d="M69.2096 57.0833C69.2096 54.3758 67.024 52.1902 64.3165 52.1902H49.2457C46.5381 52.1902 44.3525 54.3758 44.3525 57.0833C44.3525 59.5951 46.2445 61.6502 48.6911 61.9112V86.6052C48.6911 91.0742 52.312 94.6951 56.7811 94.6951C61.2501 94.6951 64.871 91.0742 64.871 86.6052V61.9112C67.3176 61.6176 69.2096 59.5625 69.2096 57.0833ZM61.6089 86.6052C61.6089 89.2801 59.456 91.433 56.7811 91.433C54.1062 91.433 51.9532 89.2801 51.9532 86.6052V61.9764H61.6089V86.6052ZM64.3165 58.7143H63.24H50.3221H49.2457C48.3323 58.7143 47.6146 57.9641 47.6146 57.0833C47.6146 56.2025 48.3323 55.4523 49.2457 55.4523H64.3165C65.2299 55.4523 65.9475 56.1699 65.9475 57.0833C65.9475 57.9967 65.2299 58.7143 64.3165 58.7143Z"
      fill="#003B71"
    />
  </svg>
)

export default ScreeningServices
