import React from "react"
import * as styles from "./styles.module.less"

const PreFooter = ({ resources, blue }) => {
  const renderResource = ({ id, title, body }) => (
    <div key={id} className={styles.flexItems}>
      <div className={styles.caption}>{title}</div>
      <div className={styles.body}>{body}</div>
    </div>
  )

  return (
    <section
      className={
        blue
          ? `${styles.preFooter} ${styles.blueBackground}`
          : `${styles.preFooter}`
      }
    >
      <div className={styles.flexContainer}>
        {resources && resources.map(renderResource)}
      </div>
    </section>
  )
}

export default PreFooter
