import React from "react"

const PhysicalTherapy = () => (
  <svg
    viewBox="0 0 133 133"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="66.6779" cy="66.0295" r="66.0295" fill="#F0F7FA" />
    <path
      d="M43.3135 65.4247L40.5418 66.1644L39.0635 70.9358V82.0691L39.6178 87.1364L41.1331 87.7652L44.1636 86.5446L46.8245 86.5076L50.0767 87.7652L51.4441 89.4297H54.2159L56.4703 89.7626C56.4703 89.7626 56.729 92.0188 56.729 92.2777C56.729 92.5366 56.729 98.6026 56.729 98.6026V103.966H79.4206V100.267V94.7189V89.7256L83.6707 89.0228H85.4816L87.4773 86.5816L90.6186 86.3967L92.8361 87.0994L94.7948 88.0611L96.2361 87.0994L97.1231 82.7349V72.6743L96.7535 69.0865L96.0144 66.7932L92.1339 65.2397L89.7317 66.4973L88.5491 70.381L88.1795 76.7429L87.9577 79.6649L74.9488 79.7389L61.3856 79.7759L52.5159 80.0348H48.6354L47.9701 72.5263L47.1571 66.4603L43.3135 65.4247Z"
      fill="#92CCF0"
    />
    <path
      d="M68.1488 74.5973C73.2107 74.5973 77.3141 70.4905 77.3141 65.4244C77.3141 60.3583 73.2107 56.2515 68.1488 56.2515C63.0869 56.2515 58.9834 60.3583 58.9834 65.4244C58.9834 70.4905 63.0869 74.5973 68.1488 74.5973Z"
      fill="#92CCF0"
    />
    <path
      d="M68.0737 47.4856C61.6062 47.4856 56.3213 42.1963 56.3213 35.7235C56.3213 29.2507 61.6062 23.9614 68.0737 23.9614C74.5411 23.9614 79.826 29.2507 79.826 35.7235C79.826 42.1963 74.5411 47.4856 68.0737 47.4856ZM68.0737 26.1437C62.7888 26.1437 58.5018 30.4343 58.5018 35.7235C58.5018 41.0127 62.7888 45.3033 68.0737 45.3033C73.3585 45.3033 77.6455 41.0127 77.6455 35.7235C77.6825 30.4343 73.3585 26.1437 68.0737 26.1437Z"
      fill="#003B71"
    />
    <path
      d="M90.5815 65.4246C89.9162 63.2423 89.4358 61.7258 89.4358 61.7258C86.664 54.6982 80.0487 51.5172 68.0746 51.5172C56.1005 51.5172 49.4852 54.6982 46.6764 61.7998C46.6764 61.7998 46.2329 63.2793 45.5677 65.3506L43.2764 64.5739C43.9416 62.3916 44.422 60.9121 44.422 60.9121C48.6721 50.1857 59.944 48.9651 68.0746 48.9651C76.2052 48.9651 87.4771 50.1857 91.6902 60.8011C91.7271 60.8751 92.2076 62.4286 92.8728 64.6109L90.5815 65.4246Z"
      fill="#003B71"
    />
    <path
      d="M34.8497 75.1522C33.704 75.1522 32.6323 74.7083 31.8192 73.8946C31.0431 73.0809 30.5996 72.0082 30.5996 70.8986V68.2355C30.5996 65.9053 32.5214 63.9819 34.8497 63.9819C35.9953 63.9819 37.0301 64.4258 37.8432 65.2395C38.6563 66.0532 39.0997 67.1259 39.0997 68.2355V70.8986C39.0997 72.0452 38.6563 73.0809 37.8432 73.8946C37.0301 74.7083 35.9584 75.1522 34.8497 75.1522ZM34.8497 66.1272C33.8518 66.1272 32.7431 66.904 32.7431 68.1985V70.8616C32.7431 71.4164 32.9649 71.9343 33.3344 72.3411C33.741 72.748 34.2584 72.9699 34.8127 72.9699C35.3671 72.9699 35.8845 72.748 36.291 72.3781C36.6975 71.9713 36.8823 71.4534 36.9193 70.8986V68.2355C36.9193 67.6807 36.6975 67.1629 36.291 66.756C35.9214 66.3491 35.404 66.1272 34.8497 66.1272Z"
      fill="#003B71"
    />
    <path
      d="M68.0747 76.7426C62.0138 76.7426 57.0615 71.8233 57.0615 65.7573C57.0615 59.6913 61.9768 54.772 68.0747 54.772C74.1357 54.772 79.051 59.6913 79.051 65.7573C79.051 71.7863 74.0988 76.7426 68.0747 76.7426ZM68.0747 56.9173C63.1964 56.9173 59.242 60.8749 59.242 65.7573C59.242 70.6397 63.1964 74.5973 68.0747 74.5973C72.9531 74.5973 76.9075 70.6397 76.9075 65.7573C76.8705 60.8749 72.9161 56.9173 68.0747 56.9173Z"
      fill="#003B71"
    />
    <path
      d="M52.2198 75.1522C51.0741 75.1522 50.0024 74.7083 49.2263 73.8946C48.4132 73.0809 47.9697 72.0082 47.9697 70.8986V68.2355C47.9697 65.9053 49.8915 63.9819 52.2198 63.9819C53.3655 63.9819 54.4003 64.4258 55.2133 65.2395C56.0264 66.0163 56.4699 67.0889 56.4699 68.2355V70.8986C56.4699 72.0452 56.0264 73.1179 55.2133 73.8946C54.4003 74.7083 53.3655 75.1522 52.2198 75.1522ZM52.2198 66.1272C51.222 66.1272 50.1132 66.904 50.1132 68.1985V70.8616C50.1132 71.4164 50.335 71.9343 50.7046 72.3411C51.1111 72.748 51.6285 72.9699 52.1828 72.9699C52.7372 72.9699 53.2546 72.748 53.6611 72.3781C54.0677 71.9713 54.2894 71.4534 54.2894 70.8986V68.2355C54.2894 67.6807 54.0677 67.1629 53.6981 66.756C53.2916 66.3491 52.7742 66.1272 52.2198 66.1272Z"
      fill="#003B71"
    />
    <path
      d="M83.9278 75.1522C82.7821 75.1522 81.7104 74.7083 80.8973 73.8946C80.0843 73.0809 79.6777 72.0082 79.6777 70.8986V68.2355C79.6777 65.9053 81.5995 63.9819 83.9278 63.9819C85.0735 63.9819 86.1083 64.4258 86.9213 65.2395C87.7344 66.0532 88.1779 67.1259 88.1779 68.2355V70.8986C88.1779 73.2288 86.2931 75.1522 83.9278 75.1522ZM83.9648 66.1272C82.9669 66.1272 81.8582 66.904 81.8582 68.1985V70.8616C81.8582 71.4164 82.0799 71.9343 82.4495 72.3411C82.856 72.748 83.3735 72.9699 83.9278 72.9699C85.0735 72.9699 85.9974 72.0452 85.9974 70.8986V68.2355C85.9974 67.6807 85.7757 67.1629 85.4061 66.756C85.0365 66.3491 84.5191 66.1272 83.9648 66.1272Z"
      fill="#003B71"
    />
    <path
      d="M101.336 75.1522C101.336 75.1522 101.299 75.1522 101.336 75.1522C100.19 75.1522 99.1186 74.7083 98.3425 73.8946C97.5294 73.0809 97.0859 72.0082 97.0859 70.8986V68.2355C97.0859 65.9053 99.0077 63.9819 101.336 63.9819C102.482 63.9819 103.516 64.4258 104.33 65.2395C105.143 66.0532 105.586 67.1259 105.586 68.2355V70.8986C105.586 72.0452 105.143 73.0809 104.33 73.8946C103.516 74.7083 102.445 75.1522 101.336 75.1522ZM101.336 66.1272C100.338 66.1272 99.2295 66.904 99.2295 68.1985V70.8616C99.2295 71.4164 99.4512 71.9343 99.8208 72.3411C100.227 72.748 100.745 72.9699 101.299 72.9699C101.853 72.9699 102.371 72.748 102.777 72.3781C103.184 71.9713 103.369 71.4534 103.406 70.8986V68.2355C103.406 67.6807 103.184 67.1629 102.777 66.756C102.408 66.3491 101.89 66.1272 101.336 66.1272Z"
      fill="#003B71"
    />
    <path
      d="M87.6983 95.6064C86.0722 95.6064 84.5939 95.1626 83.9287 93.4611C83.67 92.8323 83.67 91.9077 83.7069 91.2049C83.8548 87.5801 86.6266 84.917 90.1744 84.917C93.131 84.917 95.4963 86.3595 96.2724 88.6158C96.568 89.5404 96.568 90.0953 96.5311 90.798V91.0569C96.3832 94.4598 93.6854 94.8667 91.0614 95.2735L90.7288 95.3105C89.657 95.4585 88.6592 95.6064 87.6983 95.6064ZM90.1375 87.0993C87.4396 87.0993 85.9244 89.2076 85.8505 91.2419C85.8135 92.3145 85.8874 92.6104 85.8874 92.6474C86.22 93.4611 86.9592 93.6831 90.3223 93.1652L90.6549 93.1282C93.6115 92.6844 94.2397 92.4625 94.2767 90.983V90.724C94.3136 90.0953 94.3136 89.8733 94.1289 89.3555C93.7962 88.2089 92.4288 87.0993 90.1375 87.0993Z"
      fill="#003B71"
    />
    <path
      d="M48.4873 95.6063C47.5264 95.6063 46.4916 95.4583 45.5307 95.3104L45.1981 95.2734C42.5741 94.8665 39.8763 94.4597 39.7285 91.0568V90.7979C39.6915 90.0951 39.6545 89.5403 39.9872 88.6526C40.7263 86.3964 43.1285 84.9539 46.0851 84.9539C49.633 84.9539 52.4047 87.617 52.5526 91.2048C52.5895 91.9815 52.5895 92.9062 52.3308 93.535C51.5547 95.1624 50.1134 95.6063 48.4873 95.6063ZM45.8264 93.1651C49.1895 93.6829 49.9286 93.461 50.2612 92.6473C50.2612 92.6103 50.3721 92.3144 50.2982 91.3157C50.2243 89.1704 48.709 87.0622 46.0112 87.0622C43.7198 87.0622 42.3524 88.1718 41.9828 89.3184C41.798 89.8362 41.798 90.0582 41.835 90.6869V90.9828C41.9089 92.4623 42.5372 92.6843 45.4568 93.1281L45.8264 93.1651Z"
      fill="#003B71"
    />
    <path
      d="M41.391 89.0227C39.2475 88.5049 38.4344 86.6185 38.3975 85.139V69.6781C38.3975 66.2753 40.4301 63.9821 43.4606 63.9821C45.8998 63.9821 48.4868 65.7205 48.6715 68.6425L49.4846 78.8141C52.9955 78.7031 64.4153 78.3702 68.1479 78.3702C71.4741 78.3702 83.2264 78.7031 86.8113 78.8141L87.6243 68.6055C87.8091 65.6835 90.4331 63.9451 92.8353 63.9451C95.8658 63.9451 97.8984 66.2383 97.8984 69.6412V85.065C97.8245 87.5802 96.2723 88.4679 94.9788 88.7638L94.4614 86.6555C95.4962 86.3966 95.7179 85.8417 95.7549 85.028V69.6412C95.7549 68.5685 95.4592 66.1273 92.8353 66.1273C91.394 66.1273 89.8787 67.126 89.7679 68.7535L88.8809 79.9977C88.8439 80.5895 88.3635 80.9964 87.7722 80.9964C87.6243 80.9964 71.9545 80.5155 68.1479 80.5155C63.75 80.5155 48.6715 80.9964 48.5237 80.9964C47.9694 81.0703 47.452 80.5895 47.415 79.9977L46.528 68.7904C46.4172 67.126 44.9019 66.1273 43.4606 66.1273C40.7627 66.1273 40.5779 68.8274 40.5779 69.6412V85.065C40.5779 85.3979 40.7258 86.5815 41.9084 86.8774L41.391 89.0227Z"
      fill="#003B71"
    />
    <path
      d="M78.46 104.003V89.3556C78.46 88.8008 78.8665 88.32 79.4208 88.283L85.334 87.6912L85.5557 89.8364L80.6035 90.3543V104.04L78.46 104.003Z"
      fill="#003B71"
    />
    <path
      d="M57.8365 103.966H55.6561V90.3172L50.6299 89.8733L50.8147 87.728L56.8387 88.2828C57.393 88.3198 57.8365 88.8007 57.8365 89.3555V103.966Z"
      fill="#003B71"
    />
  </svg>
)

export default PhysicalTherapy
